.maint--container{
    padding-top: 2rem;
}
.head--test-h{
    padding-bottom: 2rem;
}
.img--div{
    display: flex;
    flex-direction: row;
    padding: 2rem;
}
.imgg--src{
    border-radius: 50%;
    height: 120px;
    width: 120px;
}

.head--test--box{
  
    padding-top: 2rem;
    padding-left: 2rem;
}
.designation--test{
    color: grey;
    line-height: 1.2rem;
}

.body--test{
    border:none
}
.text--hun{
    font-size: 18px;
    line-height: 1.3rem;
}
.card--hun{
   max-height: 150vh;
}


.bttn{
    color: black !important;
}
.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff0000' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
   }
.carousel-control-next-icon {
     background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff0000' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
   }

.btn--test--class-left{
        margin-left: -5rem !important;
   }
.btn--test--class-right{
    margin-right: -4rem !important;
}